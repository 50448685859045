import React from "react";
import "./../Styles/banner.css";
import { useNavigate } from "react-router-dom";

function Banner({ bannerImage, categoryId, catSlug }) {
  const navigation = useNavigate();
  return (
    <>
      <div className="container-fluid p-0">
        <img src={bannerImage} height='100%' width='100%' alt='bannerImage' style={{ cursor: "pointer" }} onClick={() => {
          if (categoryId && catSlug) {
            navigation(`/category/${catSlug}_${categoryId}`)
          }
        }

        }></img>
        {/* <div className="bannerpage">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-10 d-flex justify-content-center align-items-center">
                <div className="banner-1">
                  <h1>
                    <span>Exclusive Jeans</span>
                    <br></br>
                    Wear Collections
                  </h1>

                  <p id="loerm">
                    Hasasi is a globally renowned brand known for its
                    high-quality denim clothing and accessories. In India, the
                    brand offers a wide range of clothing for men, women.
                  </p>
                  <button>{t("ShopNowbanner")}</button>
                </div>
              </div>
              <div className="col-md-7">
                <div className="banner-2"></div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Banner;
